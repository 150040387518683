<template>
  <table v-if='hasRequests'
    class='text-sm table-fixed w-full'>
    <thead>
      <tr class='uppercase border-b'>
        <th class='w-24 py-2 pr-2 text-left'>요청 일자</th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterTitle'
          defaultLabel='제목'
          class='w-40 p-2 text-left border-r' />
        <table-header-filter-text
            v-bind:filter-text.sync='filterDescription'
            defaultLabel='내용'
            class='w-1/3 p-2 text-left border-r' />
        <th class='w-32 p-2 text-left'>요청자</th>
        <th class='w-32 p-2 text-left'>승인자</th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterApprovalStatus'
          defaultLabel='진행상태'
          class='w-24 p-2 text-left border-r' />
        <th class='w-40 p-2 text-left'>사건</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for='request in filteredApprovalRequests'
        :key='`my-approvals-${request.id}`'
        class='border-b'>
        <td class='py-2 pr-2'>{{request.created_on}}</td>
        <td class='p-2 border-r'>{{request.title}}</td>
        <td class='p-2 border-r'><div v-html='request.detail' v-linkified class='linked-text-container' /></td>
        <td class='p-2'>{{request.user_name}}</td>
        <td class='p-2 whitespace-pre-line'>{{requestUsers(request)}}</td>
        <td class='p-2 border-r'>{{statusString(request.status)}}</td>
        <td class='p-2'>
          <a :href='drilldownLink(request.investment_id)'
            target='_blank'
            class='underline hover:bg-gray-100 py-2'>
            {{ request.investment_name }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else
    class='bg-gray-100 text-center max-w-6xl p-16'>
    You have made 0 requests for approval
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableHeaderFilterText from '@/components/TableHeaderFilterText.vue'
import StringHelpers         from '@/utils/string-helpers'

export default {
  name: 'MyApprovalsMyRequests',
  components: {
    TableHeaderFilterText,
  },
  data () {
    return {
      filterApprovalStatus: '',
      filterTitle: '',
      filterDescription: '',
    }
  },
  computed: {
    ...mapState('approvals', [
      'approvalRequests',
    ]),
    filteredApprovalRequests () {
      if (this.filterApprovalStatus === '' &&
          this.filterTitle          === '' &&
          this.filterDescription    === '') {
        return this.approvalRequests
      } else {
        return this.approvalRequests.filter(request =>
          (this.filterApprovalStatus === '' || StringHelpers.stringIncludesInsensitive(request.status, this.filterApprovalStatus)) &&
          (this.filterTitle === ''          || StringHelpers.stringIncludesInsensitive(request.title, this.filterTitle)) &&
          (this.filterDescription === ''    || StringHelpers.stringIncludesInsensitive(request.detail, this.filterDescription))
        )
      }
    },
    hasRequests () {
      return this.approvalRequests.length > 0
    }
  },
  methods: {
    drilldownLink (investmentId) {
      return `drilldown?investment_id=${investmentId}&tab=approvals`
    },
    statusString (status) {
      return StringHelpers.koreanStatus(status)
    },
    requestUsers (request) {
      return request.approval_responses.map(resp => resp.user_name).join(',\n')
    }
  }
}
</script>
